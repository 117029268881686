const hotAppetizersMenu = [
  {
    category: "Hot Appetizers",
    itemName: "Cioconat Platter",
    description: "Mozzarella sticks, Mexican cheese, jalapeños, chicken tender, chicken mousakhan roll served with sweet chili & honey mustard dip",
    price: "JD12.50"
  },
  {
    category: "Hot Appetizers",
    itemName: "Dynamite Shrimp",
    description: "Dynamite Shrimp",
    price: "JD8.00"
  },
  {
    category: "Hot Appetizers",
    itemName: "Shrimp Kunafa",
    description: "Fried shrimp rolled with kunafa dough served with sweet chili",
    price: "JD7.50"
  },
  {
    category: "Hot Appetizers",
    itemName: "Buffalo Chicken Tenders",
    description: "With pistachio & garlic dip",
    price: "JD5.50"
  },
  {
    category: "Hot Appetizers",
    itemName: "Fried Fish Fingers",
    description: "With tartar and beetroot aioli dip",
    price: "JD7.00"
  },
  {
    category: "Hot Appetizers",
    itemName: "Potato Ball Croquettes",
    description: "With cheese dip",
    price: "JD3.50"
  },
  {
    category: "Hot Appetizers",
    itemName: "Nachos",
    description: "Topped with creamy fried beans, jalapeño, melted cheese served with Mexican salsa sour cream & avocado dip",
    price: "JD6.50"
  },
  {
    category: "Hot Appetizers",
    itemName: "Chicken Mousakhan Roll",
    description: "Chicken Mousakhan Roll with Raita Sauce",
    price: "JD4.50"
  },
  {
    category: "Hot Appetizers",
    itemName: "Chili Fries",
    description: "Chili Fries",
    price: "JD4.50"
  },
  {
    category: "Hot Appetizers",
    itemName: "French Fries",
    description: "French Fries",
    price: "JD3.00"
  },
  {
    category: "Hot Appetizers",
    itemName: "Wedges Potatoes",
    description: "Wedges Potatoes",
    price: "JD3.50"
  },
  // Adding Cheese Platter
  {
    category: "Hot Appetizers",
    itemName: "Cheese Platter",
    description: "An assortment of fine cheeses.",
    price: "JD15.00"
  }
];

export default hotAppetizersMenu;
