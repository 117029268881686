const mainCourseMenu = [
  {
    category: "Main Course",
    itemName: "Prime Beef Tenderloin",
    description: "Topped with mashed potato, vegetable puree’, mushroom sauce",
    price: "JD16.00"
  },
  {
    category: "Main Course",
    itemName: "Grilled Salmon",
    description: "Marinated with crispy garlic pistachio topped with quinoa, veggie mousse",
    price: "JD13.00"
  },
  {
    category: "Main Course",
    itemName: "Creamy Garlic Chicken",
    description: "Seasoning chicken breast dredged in flour, parmesan cheese",
    price: "JD10.00"
  },
  {
    category: "Main Course",
    itemName: "Chicken Kiev",
    description: "Fried chicken roll with flavorful cheese & butter spinach wrapped in crumbs of bread",
    price: "JD10.00"
  },
  // Adding Grilled Chicken Marinara
  {
    category: "Main Course",
    itemName: "Grilled Chicken Marinara",
    description: "Juicy grilled chicken breast topped with a rich and tangy marinara sauce, accompanied by a side of seasoned roasted vegetables and a serving of aromatic garlic-buttered pasta.", // Add more specific description if available
    price: "JD10.50"
  }
];

export default mainCourseMenu;
