const yoghurtMenu = [
  {
    category: "Yoghurt",
    itemName: "Strawberry Yoghurt",
    description: "Strawberry flavored yoghurt",
    price: "JD4.00"
  },
  {
    category: "Yoghurt",
    itemName: "Mango Yoghurt",
    description: "Mango flavored yoghurt",
    price: "JD4.00"
  },
  {
    category: "Yoghurt",
    itemName: "Blueberry Yoghurt",
    description: "Blueberry flavored yoghurt",
    price: "JD4.00"
  },
  {
    category: "Yoghurt",
    itemName: "Passion Fruit Coconut Yoghurt",
    description: "Passion Fruit Coconut flavored yoghurt",
    price: "JD4.00"
  },
  {
    category: "Yoghurt",
    itemName: "Peach Yoghurt",
    description: "Peach flavored yoghurt",
    price: "JD4.00"
  },
  {
    category: "Yoghurt",
    itemName: "Raspberry Yoghurt",
    description: "Raspberry flavored yoghurt",
    price: "JD4.00"
  }
];

export default yoghurtMenu;