const juicesSmoothiesMenu = [
  {
    category: "Juices & Smoothies",
    itemName: "Orange Juice",
    description: "Fresh orange juice",
    price: "JD4.50"
  },
  {
    category: "Juices & Smoothies",
    itemName: "Lemon Juice",
    description: "Fresh lemon juice",
    price: "JD4.50"
  },
  {
    category: "Juices & Smoothies",
    itemName: "Mango Juice",
    description: "Fresh mango juice",
    price: "JD4.50"
  },
  {
    category: "Juices & Smoothies",
    itemName: "Strawberry Pineapple Juice",
    description: "Fresh strawberry pineapple juice",
    price: "JD4.50"
  },
  {
    category: "Juices & Smoothies",
    itemName: "Pomegranate Juice",
    description: "Fresh pomegranate juice",
    price: "JD4.50"
  },
  {
    category: "Juices & Smoothies",
    itemName: "Carrot Papaya Juice",
    description: "Fresh carrot papaya juice",
    price: "JD4.50"
  },
  {
    category: "Juices & Smoothies",
    itemName: "Kiwi Guava Juice",
    description: "Fresh kiwi guava juice",
    price: "JD4.50"
  },
  {
    category: "Juices & Smoothies",
    itemName: "Lemon Mint Juice",
    description: "Fresh lemon mint juice",
    price: "JD4.50"
  }
];

export default juicesSmoothiesMenu;
