const sandwichesMenu = [
  // Beef Burgers
  {
    category: "Sandwiches",
    itemName: "Our Signature Burger",
    description: "Burger made fresh in house with Angus beef chargrilled served with classic burger garnish, BBQ mushroom, onion, pickles American cucumber",
    price: "JD7.50"
  },
  {
    category: "Sandwiches",
    itemName: "Alfredo Beef Burger",
    description: "Rich Alfredo sauce over a chargrilled beef patty, topped with parmesan and fresh herbs, served in a soft bun",
    price: "JD8"
  },
  {
    category: "Sandwiches",
    itemName: "BBQ Beef Burger",
    description: "Juicy beef burger smothered in our signature BBQ sauce, topped with crispy onion rings and cheddar cheese",
    price: "JD7.5"
  },
  {
    category: "Sandwiches",
    itemName: "Blue Cheese Beef Burger",
    description: "Savory beef patty with a bold blue cheese topping, fresh lettuce, and tangy sauce, nestled in a toasted bun",
    price: "JD8"
  },
  {
    category: "Sandwiches",
    itemName: "Beef Shawarma",
    description: "Tender slices of marinated beef, wrapped in a soft tortilla with tahini, pickles, tomatoes, and onions",
    price: "JD7.50"
  },

  // Chicken Sandwiches
  {
    category: "Sandwiches",
    itemName: "Pressed Italian Picnic",
    description: "Focaccia bread, marinated chicken, dry tomato, pesto, buffalo mozzarella cheese",
    price: "JD6.75"
  },
  {
    category: "Sandwiches",
    itemName: "Aroma Chicken Sandwich",
    description: "Garlic chicken with onion, mushroom, sundry tomato, Dijon mustard cream",
    price: "JD6.50"
  },
  {
    category: "Sandwiches",
    itemName: "Chicken Shawarma",
    description: "Marinated chicken with garlic paste, pickles, lettuce wrapped in tortilla bread",
    price: "JD6.50"
  },
  {
    category: "Sandwiches",
    itemName: "Chicken Enchilada",
    description: "Seasonal chicken wrap with tortilla bread, bell pepper, onion, frisca, melted cheese",
    price: "JD6.50"
  },
  {
    category: "Sandwiches",
    itemName: "Buffalo Chicken Burger",
    description: "Spicy buffalo sauce drizzled over a grilled chicken patty, topped with crisp lettuce and blue cheese",
    price: "JD6.5"
  },
  {
    category: "Sandwiches",
    itemName: "Dynamite Chicken Burger",
    description: "Grilled chicken burger with a fiery dynamite sauce, topped with a cooling slaw and melted cheese",
    price: "JD6.50"
  },
  {
    category: "Sandwiches",
    itemName: "Mix Cheese Chicken Burger",
    description: "Tender chicken burger layered with a blend of cheeses, fresh tomato, and a light mayo dressing",
    price: "JD6.5"
  },
  {
    category: "Sandwiches",
    itemName: "Chicken Club Sandwich",
    description: "Classic club sandwich with layers of chicken, bacon, lettuce, tomato, and mayo, served in toasted bread",
    price: "JD6"
  },
  
  // Other Sandwiches
  {
    category: "Sandwiches",
    itemName: "Steak Sandwich",
    description: "Succulent shaved beef steak, mushroom, onion, creamy mustard, melted cheese",
    price: "JD8.00"
  },
  {
    category: "Sandwiches",
    itemName: "Quesadilla Sandwich",
    description: "A fusion of Mexican and sandwich, with melted cheese, peppers, and tender pieces of chicken in a grilled tortilla",
    price: "JD6.50"
  },
  {
    category: "Sandwiches",
    itemName: "Halloumi Sandwich",
    description: "Grilled halloumi cheese with crisp lettuce, tomato, and a light dressing, served in a soft bun",
    price: "JD4"
  }
];

export default sandwichesMenu;
