const cocktailsMenu = [
  {
    category: "Cocktails",
    itemName: "Cioconat Cocktail",
    description: "Pomegranate, orange, strawberry, vanilla ice cream",
    price: "JD4.40"
  },
  {
    category: "Cocktails",
    itemName: "Green Apple",
    description: "Mint ginger",
    price: "JD4.00"
  },
  {
    category: "Cocktails",
    itemName: "Mixed Fruit",
    description: "Banana, strawberry, orange, guava, mango",
    price: "JD4.25"
  },
  {
    category: "Cocktails",
    itemName: "Ginger Colada",
    description: "Coconut, passion fruit, pineapple, ginger",
    price: "JD4.40"
  },
  {
    category: "Cocktails",
    itemName: "Sunset Fruit",
    description: "Orange, pineapple, strawberry, passion fruit",
    price: "JD4.25"
  },
  {
    category: "Cocktails",
    itemName: "Dreamy Mango",
    description: "Orange, mango, strawberry, strawberry ice cream",
    price: "JD4.40"
  },
  {
    category: "Cocktails",
    itemName: "Avocado Coconut",
    description: "Avocado, vanilla ice cream, honey, coconut, milk whipped cream",
    price: "JD5.00"
  },
  {
    category: "Cocktails",
    itemName: "Avocado Pomegranate",
    description: "Avocado, milk, pomegranate, vanilla ice cream whipped cream",
    price: "JD5.00"
  },
  {
    category: "Cocktails",
    itemName: "Sun Rise",
    description: "Banana, raspberry, mango, orange",
    price: "JD4.25"
  },
  {
    category: "Cocktails",
    itemName: "Apple Pie",
    description: "Banana, apple, vanilla, cinnamon, milk",
    price: "JD4.00"
  },
  {
    category: "Cocktails",
    itemName: "Blueberry Delight",
    description: "Coconut, blueberry, banana, yoghurt, milk, strawberry",
    price: "JD4.50"
  }
];

export default cocktailsMenu;