import React from "react";
import Videosection from "../Menhome/Videosection";
import Special from "../Menhome/Special";
import Contact from "../Menhome/Contact";
import Banner from "../Menhome/Banner";
import Offer from "../Menhome/Offer";
import Story from "../Menhome/Story";
import Specialdish from "../Menhome/Specialdish";
import Menusection from "../Menhome/Menusection";
import Whyus from "../Menhome/Whyus";
import Teem from "../Menhome/Teem";
import Reacntupdate from "../Menhome/Recntupdates";
import { Link } from "react-router-dom";
import sidebarlogo from "../../assets/images/resource/logo.svg";

function Main() {
  return (
    <>
      <div className="menu-backdrop"> </div>

      <section className="hidden-bar">
        <div className="inner-box">
          <div className="cross-icon hidden-bar-closer">
            <span className="far fa-close"></span>
          </div>
          <div className="logo-box">
            <Link to="/" title="Cioconat">
              <img
                src={sidebarlogo}
                alt=""
                title="cioconat"
              />
            </Link>
          </div>

          <div className="side-menu">
            <ul className="navigation clearfix">
              <li className="current dropdown">
                <Link to="/">Home</Link>                
              </li>
              <li className="dropdown">
                <Link to="/menu">Menus</Link>
                <ul>
                  <li>
                    <Link to="/menu">Drinks</Link>
                  </li>
                  <li>
                    <Link to="/menu">Food</Link>
                  </li>
                  <li>
                    <Link to="/menu">Shiesha</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/ourchefs">Our chefs</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>

          <h2>Visit Us</h2>
          <ul className="info">
            <li>
              Al Shab St 253, Dabouq, <br /> Amman, Jordan
            </li>
            <li>Open: 9.00 am - 1.00 am</li>
            <li>
              <Link to="mailto:info@cioconatcafe.com">
                info@cioconatcafe.com
              </Link>
            </li>
          </ul>
          <div className="separator">
            <span></span>
          </div>
          <div className="booking-info">
            <div className="bk-title">Booking request</div>
            <div className="bk-no">
              <Link to="tel:+962 (6) 541 2484">+962 (6) 541 2484</Link>
            </div>
          </div>
        </div>
      </section>

      <Banner />
      <Offer />
      <Story />
      {/* <Specialdish /> */}
      <Menusection />
      <Special />
      <Contact />
      {/* <Whyus /> */}
      {/* <Teem /> */}
      {/* <Videosection /> */}
      <Reacntupdate />
    </>
  );
}

export default Main;
