const shishaMenu = [
  {
    category: "Shisha",
    itemName: "Two Apples",
    description: "Two Apples flavor",
    price: "JD7.00"
  },
  {
    category: "Shisha",
    itemName: "Two Apple Mazaya",
    description: "Two Apple Mazaya flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Lemon & Mint",
    description: "Lemon & Mint flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Gum with Cinnamon",
    description: "Gum with Cinnamon flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Arrow Gum",
    description: "Arrow Gum flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Candy",
    description: "Candy flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Mestika",
    description: "Mestika flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Watermelon & Mint",
    description: "Watermelon & Mint flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Grape",
    description: "Grape flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Grape & Mint",
    description: "Grape & Mint flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Grape with Berry",
    description: "Grape with Berry flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Orange & Mint",
    description: "Orange & Mint flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Special Fruit",
    description: "Special Fruit flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Mint",
    description: "Mint flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Blue Mist",
    description: "Blue Mist flavor",
    price: "JD6.50"
  },
  {
    category: "Shisha",
    itemName: "Zagloul",
    description: "Zagloul flavor",
    price: "JD6.50"
  }
];


export default shishaMenu;
