import React, { useState } from "react";
import { Link } from "react-router-dom";
import bgone from "../../assets/images/background/bg-5.png";
import Bgtwo from "../../assets/images/background/bg-6.png";
import five from "../../assets/images/resource/menu-image-5.png";
import six from "../../assets/images/resource/menu-image-6.png";
import seven from "../../assets/images/resource/menu-image-7.png";
import eight from "../../assets/images/resource/menu-image-8.png";
import nine from "../../assets/images/resource/menu-image-9.png";
import ten from "../../assets/images/resource/menu-image-10.png";

function Menusection() {
  const [tabMenu, tabActive] = useState({ a: true });

  return (
    <>
      <section className="menu-section">
        <div className="left-bg">
          <img src={bgone} alt="" title="" />
        </div>

        <div className="right-bg">
          <img src={Bgtwo} alt="" title="" />
        </div>

        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Special selection</span>
            </div>

            <div className="pattern-image">
              <img
                src={require("../../assets/images/icons/separator.svg").default}
                alt="mySvgImage"
              />
            </div>

            <h2>Delicious Menu</h2>
          </div>

          <div className="tabs-box menu-tabs">
            <div className="buttons">
              <ul className="tab-buttons clearfix">
                <li
                  className={`tab-btn ${tabMenu.a && "active-btn"}`}
                  onClick={() => tabActive({ a: true })}
                  data-tab="#tab-1"
                >
                  <span>MORNING</span>
                </li>
                <li
                  className={`tab-btn ${tabMenu.b && "active-btn"}`}
                  onClick={() => tabActive({ b: true })}
                  data-tab="#tab-2"
                >
                  <span>WEEKDAY LUNCH</span>
                </li>
                <li
                  className={`tab-btn ${tabMenu.c && "active-btn"}`}
                  onClick={() => tabActive({ c: true })}
                  data-tab="#tab-3"
                >
                  <span>DINNER</span>
                </li>
                <li
                  className={`tab-btn ${tabMenu.d && "active-btn"}`}
                  onClick={() => tabActive({ d: true })}
                  data-tab="#tab-4"
                >
                  <span>DRINKS</span>
                </li>
              </ul>
            </div>
            <div className="tabs-content">
              <div className={`tab ${tabMenu.a && "active-tab"}`} id="tab-1">
                <div className="row clearfix">
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={five} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Our Signature Breakfast</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD12.00</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Manakish, foul, hummus, labneh, cheese halloumi,
                              falafel, omlette, veggies platter, tea or American
                              coffee
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={six} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Kalayet banadoura with meat</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD5.50</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">Kalayet banadoura with meat</Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={seven} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Croque Madame croissant</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD3.00</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Croque Madame croissant stuffed with turkey salami
                              & cheese
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={eight} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">L’exotique Quinoa Salad</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD7.50</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Quinoa, shrimp, avocado, cucumber, spring onion,
                              parsley topped with beetroot cherry, vinaigrette
                              green oil
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={nine} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Greek Salad</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD5.50</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Crispy lettuce, with fresh herbs, cherry tomato,
                              bell pepper, oregano, cheese, olive, sliced
                              cucumber with french dressing
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={ten} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Kani Salad</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD8.50</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Japanese crab with cucumber, carrot in Thai
                              dressing topped with crispy panko
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`tab ${tabMenu.b && "active-tab"}`} id="tab-2">
                <div className="row clearfix">
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={five} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Cioconat Platter</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD12.00</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Mozzarella sticks, mexican cheese, jalapeños,
                              chicken tender, chicken mousakhan roll served with
                              sweet chili & honey mustard dip
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={six} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Shrimp Kunafa</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD7.50</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Fried shrimp rolled with kunafa dough served with
                              sweet chili
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={seven} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Chicken Tawook Provencal</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD8.00</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Grilled chicken cubes with butter garlic sauce
                              mushroom, emmental cheese baked in oven
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={eight} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Seafood Termidore</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD10.50</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Sauted seafood with bell pepper, garlic, onion,
                              mushroom, creamy saffron sauce, melted cheese
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={nine} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Chicken Shawarma</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD6.50</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Marinated chicken with garlic paste, pickles,
                              lettuce wrapped in tortilla bread
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={ten} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Chicken Enchilada</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD6.50</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Seasonal chicken wrap with tortilla bread, bell
                              pepper, onion, frisca, melted cheese
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`tab ${tabMenu.c && "active-tab"}`} id="tab-3">
                <div className="row clearfix">
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={five} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Prime Beef Ribeye Silver</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD27.00</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Topped with mashed potato, vegetable sticks, fresh
                              herbs sauce
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={six} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Prime Beef Tenderloin</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD16.00</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Topped with mashed potato, vegetable puree’,
                              mushroom sauce
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={seven} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Grilled Salmon</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>$10.00</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Marinated with crispy garlic pistachio topped with
                              quinoa, veggie mousse
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={eight} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Seafood Termidore</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD10.50</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Sauted seafood with bell pepper, garlic, onion,
                              mushroom, creamy saffron sauce, melted cheese
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={nine} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Creamy Garlic Chicken</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD10.00</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Seasoning chicken breast dredged in flour,
                              parmesan cheese
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={ten} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Authentic Meatballs</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD9.50</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Stuffed meatball with cheese cooked with tomato
                              concasse, croquette potato
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`tab ${tabMenu.d && "active-tab"}`} id="tab-4">
                <div className="row clearfix">
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={five} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Coco Mocha</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD4.50</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Dark chocolate sauce, coconut, espresso, steamed
                              milk
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={six} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Caramel Macchiato</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD4.50</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">Caramel Macchiato</Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={seven} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Avocado Coconut</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD5.00</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Avocado, vanilla ice cream, honey, coconut, milk
                              whipped cream
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={eight} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Blackberry Green Apple</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD3.50</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Lemon rancho, blackberry, green apple, soda
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={nine} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Frappuccino</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD4.75</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Caramel, vanilla, chocolate, hazelnut
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="dish-block">
                        <div className="inner-box">
                          <div className="dish-image">
                            <Link to="#">
                              <img src={ten} alt="" />
                            </Link>
                          </div>
                          <div className="title clearfix">
                            <div className="ttl clearfix">
                              <h5>
                                <Link to="#">Mojito</Link>
                              </h5>
                            </div>
                            <div className="price">
                              <span>JD4.25</span>
                            </div>
                          </div>
                          <div className="text desc">
                            <Link to="#">
                              Red bull, soda, ginger ale, sprite
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="open-timing">
            <div className="hours">
              During winter daily from{" "}
              <span className="theme_color">7:00 pm</span> to{" "}
              <span className="theme_color">9:00 pm</span>
            </div>
            <div className="link-box">
              <Link to="/menu" className="theme-btn btn-style-two clearfix">
                <span className="btn-wrap">
                  <span className="text-one">view all menu</span>
                  <span className="text-two">view all menu</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Menusection;
