const milkshakeMenu = [
  {
    category: "Milkshake",
    itemName: "Vanilla Milkshake",
    description: "Vanilla flavored milkshake",
    price: "JD4.40"
  },
  {
    category: "Milkshake",
    itemName: "Chocolate Milkshake",
    description: "Chocolate flavored milkshake",
    price: "JD4.40"
  },
  {
    category: "Milkshake",
    itemName: "Strawberry Milkshake",
    description: "Strawberry flavored milkshake",
    price: "JD4.40"
  },
  {
    category: "Milkshake",
    itemName: "Arabia Milkshake",
    description: "Arabia flavored milkshake",
    price: "JD4.40"
  },
  {
    category: "Milkshake",
    itemName: "KitKat Milkshake",
    description: "KitKat flavored milkshake",
    price: "JD4.40"
  },
  {
    category: "Milkshake",
    itemName: "Caramel Milkshake",
    description: "Caramel flavored milkshake",
    price: "JD4.40"
  },
  {
    category: "Milkshake",
    itemName: "Croquant Milkshake",
    description: "Croquant flavored milkshake",
    price: "JD4.40"
  },
  {
    category: "Milkshake",
    itemName: "Lotus Milkshake",
    description: "Lotus flavored milkshake",
    price: "JD4.40"
  },
  {
    category: "Milkshake",
    itemName: "Nutella Milkshake",
    description: "Nutella flavored milkshake",
    price: "JD4.40"
  },
  {
    category: "Milkshake",
    itemName: "Pistachio Milkshake",
    description: "Pistachio flavored milkshake",
    price: "JD4.40"
  },
  {
    category: "Milkshake",
    itemName: "Cheese Cake Milkshake",
    description: "Cheese Cake flavored milkshake",
    price: "JD4.40"
  }
];

export default milkshakeMenu;