const pastaMenu = [
  {
    category: "Pasta",
    itemName: "Penne Arriabiata",
    description: "Penne pasta cooked with spicy tomato sauce and sliced black olives",
    price: "JD5.50"
  },
  {
    category: "Pasta",
    itemName: "Fettuccine Alfredo",
    description: "Italian pasta dish tossed with butter and parmesan cheese, chicken mushroom, pesto cream",
    price: "JD6.50"
  },
  {
    category: "Pasta",
    itemName: "Pasta Linguine",
    description: "Cooked with garlic seafood, fresh basil, rose sose",
    price: "JD7.00"
  }
];

export default pastaMenu