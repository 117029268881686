import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import bg5 from '../../assets/images/background/bg-5.png'
import bg6 from '../../assets/images/background/bg-6.png'
import toronto from '../../assets/images/resource/toronto.png'
import paris from '../../assets/images/resource/paris.png'
import dubai from '../../assets/images/resource/dubai.png'
import ny from '../../assets/images/resource/ny.png'

function Book() {
    const [customerName, setCustomerName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [bookingTime, setBookingTime] = useState('');
    const [numberOfGuests, setNumberOfGuests] = useState('');
    const [specialRequests, setSpecialRequests] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await fetch('https://your-api-endpoint.com/bookings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                customerName,
                mobileNumber,
                bookingTime,
                numberOfGuests,
                specialRequests,
            }),
        });

        if (response.ok) {
            // handle successful booking
        } else {
            // handle error
        }
    };

    return (
        <>
            <section className="online-reservation inner-page">
                {/* ... */}
                <div className="default-form reservation-form">
                    <form onSubmit={handleSubmit}>
                        <div className="row clearfix">
                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                <div className="field-inner">
                                    <input type="text" placeholder="Customer Name" value={customerName} onChange={(e) => setCustomerName(e.target.value)} required />
                                </div>
                            </div>
                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                <div className="field-inner">
                                    <input type="tel" pattern="[0-9]{10}" placeholder="Mobile Number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} required />
                                </div>
                            </div>
                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                <div className="field-inner">
                                    <input type="datetime-local" placeholder="Booking Time" value={bookingTime} onChange={(e) => setBookingTime(e.target.value)} required />
                                </div>
                            </div>
                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                <div className="field-inner">
                                    <input type="number" min="1" max="100" placeholder="Number of Guests" value={numberOfGuests} onChange={(e) => setNumberOfGuests(e.target.value)} required />
                                </div>
                            </div>
                            <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                <div className="field-inner">
                                    <input type="text" placeholder="Special Requests" value={specialRequests} onChange={(e) => setSpecialRequests(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="theme-btn btn-style-one clearfix">
                            <span className="btn-wrap">
                                <span className="text-one">book a table</span>
                                <span className="text-two">book a table</span>
                            </span>
                        </button>
                    </form>
                    {/* ... */}
                </div>
                {/* ... */}
            </section> 
        </>
    )
}

export default Book
