import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/resource/logo.svg";
import logonew from "../../assets/images/logo.svg";
import imgone from "../../assets/images/resource/menu-image-1.jpg";
import imgtwo from "../../assets/images/resource/menu-image-2.jpg";
import Imgthree from "../../assets/images/resource/menu-image-3.jpg";
import Imgfour from "../../assets/images/resource/menu-image-4.jpg";

function Main() {
  const [active, setActive] = useState();
  const [show, setShow] = useState();
  const [menu, setMenu] = useState();
  const [page, setPage] = useState();
  const [drop, setDrop] = useState();
  const [lastscrool, setlastscrool] = useState(0);

  const [scrolled, setScrolled] = useState(false);
  const [Nav, setNav] = useState(true);

  const handleScroll = () => {
    const offset = window.scrollY;
    setlastscrool(offset);

    if (offset > 1000 && offset < lastscrool) {
      setNav(true);
      setScrolled(true);
    } else if (offset > 1000) {
      setNav(false);
    } else if (offset > 200) {
      setNav(true);
      setScrolled(true);
    } else {
      setScrolled(false);
      setNav(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <>
      {active && (
        <div
          className="menu-backdrop"
          style={{ opacity: "1", visibility: "visible" }}
          onClick={() => setActive(false)}
        ></div>
      )}

      <section
        className={`hidden-bar ${active && "visible-sidebar"}`}
        onClick={() => setActive(false)}
      >
        <div className="inner-box">
          <div
            className="cross-icon hidden-bar-closer"
            onClick={() => setActive(false)}
          >
            <span className="far fa-close"> </span>
          </div>
          <div className="logo-box">
            <Link to="/" title="Cioconat">
              <img src={logo} alt="" title="Cioconat" />
            </Link>
          </div>

          <div className="side-menu">
            <ul className="navigation clearfix">
              <li className="current dropdown">
                <Link
                  to="/"
                  onClick={() => setShow(show === true ? false : true)}
                >
                  Home
                  {/* <button type="button" className="btn-expander">
                    <i className="far fa-angle-down"></i>
                  </button> */}
                </Link>
                {/* <ul style={{ display: show ? "block" : "none" }}>
                  <li>
                    <Link to="/hometwo">Home 1 Left Header</Link>
                  </li>
                  <li>
                    <Link to="/">Home 1 Center Header</Link>
                  </li>
                  <li>
                    <Link to="/homethree">Home 3 Video Hero</Link>
                  </li>
                </ul> */}
              </li>
              <li className="dropdown">
                <Link
                  to="/menu"
                  onClick={() => setMenu(menu === true ? false : true)}
                >
                  Menus
                  <button type="button" className="btn-expander">
                    <i className="far fa-angle-down"></i>
                  </button>
                </Link>
                <ul style={{ display: menu ? "block" : "none" }}>
                  <li>
                    <Link to="/menu">Drinks</Link>
                  </li>
                  <li>
                    <Link to="/menu">Food</Link>
                  </li>
                  <li>
                    <Link to="/menu">Shiesha</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/ourchefs">Our chefs</Link>
              </li>
              <li>
                <Link to="/contact"> Contact </Link>
              </li>
            </ul>
          </div>

          <h2>Visit Us</h2>
          <ul className="info">
            <li>
              Al-Sha'b St, Dabouq, <br /> Amman 11822, JO{" "}
            </li>
            <li>Open: 9.00 am - 1.00 am</li>
            <li>
              <Link to="mailto:booking@cioconatlounge.com">
                booking@cioconatlounge.com
              </Link>
            </li>
          </ul>
          <div className="separator">
            <span></span>
          </div>
          <div className="booking-info">
            <div className="bk-title">Booking request</div>
            <div className="bk-no">
              <Link to="tel:+962--(6)-541-2484">+962-(6)-541-2484</Link>
            </div>
          </div>
        </div>
      </section>
      {Nav && (
        <header
          className={`main-header ${scrolled && "fixed-header"} header-down`}
        >
          <div className="header-top">
            <div className="auto-container">
              <div className="inner clearfix">
                <div className="top-left clearfix">
                  <ul className="top-info clearfix">
                    <li>
                      <i className="icon far fa-map-marker-alt"></i> Al-Sha'b
                      St, Dabouq, Amman 11822, JO
                    </li>
                    <li>
                      <i className="icon far fa-clock"></i> Daily : 9.00 am to
                      1.00 am
                    </li>
                  </ul>
                </div>
                <div className="top-right clearfix">
                  <ul className="top-info clearfix">
                    <li>
                      <Link to="tel:+96265412484">
                        <i className="icon far fa-phone"></i> +962 (6) 541 2484{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="mailto:info@cioconatcafe.com">
                        <i className="icon far fa-envelope"></i>{" "}
                        info@cioconatcafe.com
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="header-upper header-fixed">
            <div className="auto-container">
              <div className="main-box d-flex justify-content-between align-items-center flex-wrap">
                <div className="links-box clearfix">
                  <div className="nav-toggler">
                    <button className="hidden-bar-opener">
                      <span
                        className="hamburger"
                        onClick={() => setActive(true)}
                      >
                        <span className="top-bun"></span>
                        <span className="meat"></span>
                        <span className="bottom-bun"></span>
                      </span>
                    </button>
                  </div>
                </div>

                <div className="logo-box">
                  <div className="logo">
                    <Link to="/" title="cioconat">
                      <img src={logonew} alt="" title="Cioconat" />
                    </Link>
                  </div>
                </div>

                <div className="nav-box clearfix">
                  <div className="nav-outer clearfix">
                    <nav className="main-menu">
                      <ul className="navigation clearfix">
                        <li className="current dropdown">
                          <Link to="/">Home</Link>
                          <ul>
                            <li>
                              {" "}
                              <Link to="/hometwo">Home 1 Left Header</Link>
                            </li>
                            <li>
                              {" "}
                              <Link to="/">Home 1 Center Header</Link>
                            </li>
                            <li>
                              {" "}
                              <Link to="/homethree">Home 3 Video Hero</Link>
                            </li>
                          </ul>
                        </li>
                        <li className="dropdown has-mega-menu">
                          <Link to="/menu">Menus</Link>
                          <ul>
                            <li>
                              <div className="mega-menu">
                                <div className="menu-inner">
                                  <div className="auto-container">
                                    <div className="row clearfix">
                                      <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                        <div className="image">
                                          <Link to="/]]menuone">
                                            <img src={imgone} alt="" />
                                          </Link>
                                        </div>
                                        <div className="title">
                                          <Link to="/menuone">Menu list 1</Link>
                                        </div>
                                      </div>
                                      <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                        <div className="image">
                                          <Link to="/menutwo">
                                            <img src={imgtwo} alt="" />
                                          </Link>
                                        </div>
                                        <div className="title">
                                          <Link to="menutwo">Menu list 2</Link>
                                        </div>
                                      </div>
                                      <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                        <div className="image">
                                          <Link to="/menuthree">
                                            <img src={Imgthree} alt="" />
                                          </Link>
                                        </div>
                                        <div className="title">
                                          <Link to="menuthree">
                                            Menu list 3
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                        <div className="image">
                                          <Link to="/menufour">
                                            <img src={Imgfour} alt="" />
                                          </Link>
                                        </div>
                                        <div className="title">
                                          <Link to="/menufour">
                                            Menu list 4
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li>
                          <Link to="/ourchefs">Our chefs</Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/">Pages</Link>
                          <ul>
                            <li>
                              {" "}
                              <Link to="#">Dropdown Menu 1</Link>
                            </li>
                            <li>
                              {" "}
                              <Link to="#">Dropdown Menu 2</Link>
                            </li>
                            <li>
                              {" "}
                              <Link to="#">Dropdown Menu 3</Link>
                            </li>
                            <li className="dropdown">
                              <Link to="#">Dropdown Menu 4</Link>
                              <ul>
                                <li>
                                  {" "}
                                  <Link to="#">Dropdown Menu level 2</Link>
                                </li>
                                <li>
                                  {" "}
                                  <Link to="#">Dropdown Menu level 2</Link>
                                </li>
                                <li>
                                  {" "}
                                  <Link to="#">Dropdown Menu Level 3</Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to="#">Dropdown Lorem 5</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="#">Blog</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>

                <div className="link link-btn">
                  <Link
                    to="/reservation"
                    className="theme-btn btn-style-one clearfix"
                  >
                    <span className="btn-wrap">
                      <span className="text-one">find a table</span>
                      <span className="text-two">find a table</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
}

export default Main;
