const soupMenu = [
  {
    category: "Soup",
    itemName: "Seafood Vegetables Soup",
    description: "Seafood Vegetables Soup",
    price: "JD4.50"
  },
  {
    category: "Soup",
    itemName: "Chicken Mushroom",
    description: "Chicken Mushroom",
    price: "JD3.50"
  },
  // Adding new items
  {
    category: "Soup",
    itemName: "Corn Soup",
    description: "Corn Soup",
    price: "JD4"
  },
  {
    category: "Soup",
    itemName: "Lentil Soup",
    description: "Lentil Soup",
    price: "JD4"
  },
  {
    category: "Soup",
    itemName: "Vegetable Soup",
    description: "Vegetable Soup",
    price: "JD4"
  },
];

export default soupMenu;
