import React from "react";
import { Link } from "react-router-dom";
import bgone from "../../assets/images/background/bg-16.png";
import shishamenu from "../../assets/images/resource/shishamenu.png";
import Accordion from 'react-bootstrap/Accordion';
import {shishaGroup} from '../../data/allMenusA';
// assuming allMenus is the combined array

function Smenu3() {
  return (
    <>
      <section className="menu-one">
        <div className="right-bg">
          <img src={bgone} alt="" title="" />
        </div>
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span> Shisha</span>
            </div>
            <div className="pattern-image">
              <img
                src={
                  require("../../assets/images/icons/separator.svg").default
                }
                alt="mySvgImage"
              />
            </div>
            <h2>cioconat Shisha Selection</h2>
          </div>

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={shishamenu} alt="" />
                </div>
              </div>
            </div>

            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">
                <Accordion defaultActiveKey="0">
                  {Object.keys(shishaGroup).map((category, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header className="custom-accordion-header">{category}</Accordion.Header>
                      <Accordion.Body>
                        {shishaGroup[category].map((item, itemIndex) => (
                          <div key={itemIndex} className="dish-block">
                            <div className="inner-box">
                              <div className="title clearfix">
                                <div className="ttl clearfix">
                                  <h5>
                                    <Link to="#">{item.itemName}</Link>
                                  </h5>
                                </div>
                                <div className="price">
                                  <span>{item.price}</span>
                                </div>
                              </div>
                              <div className="text desc">
                                <Link to="#">{item.description}</Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Smenu3;
