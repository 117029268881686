const softDrinkMenu = [
  {
    category: "Soft Drink",
    itemName: "Mineral Water",
    description: "Mineral Water",
    price: "JD1.15"
  },
  {
    category: "Soft Drink",
    itemName: "Sparkling Water",
    description: "Sparkling Water",
    price: "JD2.25"
  },
  {
    category: "Soft Drink",
    itemName: "Energy Drink",
    description: "Energy Drink",
    price: "JD3.75"
  },
  {
    category: "Soft Drink",
    itemName: "G Drink",
    description: "G Drink",
    price: "JD3.50"
  },
  {
    category: "Soft Drink",
    itemName: "Barbican",
    description: "Barbican",
    price: "JD3.75"
  },
  {
    category: "Soft Drink",
    itemName: "Coca-Cola",
    description: "Coca-Cola",
    price: "JD2.75"
  },
  {
    category: "Soft Drink",
    itemName: "Sprite Zero",
    description: "Sprite Zero",
    price: "JD2.75"
  },
  {
    category: "Soft Drink",
    itemName: "Fanta",
    description: "Fanta",
    price: "JD2.75"
  }
];

export default softDrinkMenu;