const breakfastMenu = [
  {
    category: "Breakfast",
    itemName: "Our Signature Breakfast",
    description: "Manakish, foul, hummus, labneh, cheese halloumi, falafel, omlette, veggies platter, tea or American coffee",
    price: "JD12.00"
  },
  {
    category: "Breakfast",
    itemName: "Create the Omlette of your Choice",
    description: "Eggs, cheese, veggies, turkey, salami, served with hashbrown potato & grill cherry tomato, mushroom",
    price: "JD3.50"
  },
  {
    category: "Breakfast",
    itemName: "Croissant stuffed with eggs",
    description: "Croissant stuffed with scrambled eggs, turkey, cheese baby rocket",
    price: "JD3.50"
  },
  {
    category: "Breakfast",
    itemName: "Croque Madame",
    description: "Croque Madame croissant stuffed with turkey salami & cheese",
    price: "JD3.00"
  },
  {
    category: "Breakfast",
    itemName: "Foul with condiment",
    description: "Foul with condiment",
    price: "JD2.50"
  },
  {
    category: "Breakfast",
    itemName: "Falafel with condiment",
    description: "Falafel with condiment",
    price: "JD1.75"
  },
  {
    category: "Breakfast",
    itemName: "Hummus with condiment",
    description: "Hummus with condiment",
    price: "JD1.75"
  },
  {
    category: "Breakfast",
    itemName: "Hummus with meat",
    description: "Hummus with meat",
    price: "JD3.00"
  },
  {
    category: "Breakfast",
    itemName: "Fatteh hummus & batenjan",
    description: "Fatteh hummus & batenjan",
    price: "JD4.00"
  },
  {
    category: "Breakfast",
    itemName: "Cheese halloumi",
    description: "Cheese halloumi",
    price: "JD4.00"
  },
  {
    category: "Breakfast",
    itemName: "Moufaraket potato",
    description: "Moufaraket potato",
    price: "JD2.25"
  },
  {
    category: "Breakfast",
    itemName: "Kalayet banadoura with meat",
    description: "Kalayet banadoura with meat",
    price: "JD5.50"
  },
  {
    category: "Breakfast",
    itemName: "Pickles & olives",
    description: "Pickles & olives",
    price: "JD3.00"
  }
];

export default breakfastMenu;