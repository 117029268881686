const fruityFrappeMenu = [
  {
    category: "Fruity Frappe",
    itemName: "Green Apple Frappe",
    description: "Green Apple flavored frappe",
    price: "JD4.50"
  },
  {
    category: "Fruity Frappe",
    itemName: "Kiwi Frappe",
    description: "Kiwi flavored frappe",
    price: "JD4.50"
  },
  {
    category: "Fruity Frappe",
    itemName: "Grapefruit Frappe",
    description: "Grapefruit flavored frappe",
    price: "JD4.50"
  },
  {
    category: "Fruity Frappe",
    itemName: "Coconut Frappe",
    description: "Coconut flavored frappe",
    price: "JD4.50"
  },
  {
    category: "Fruity Frappe",
    itemName: "Peach Frappe",
    description: "Peach flavored frappe",
    price: "JD4.50"
  },
  {
    category: "Fruity Frappe",
    itemName: "Strawberry Frappe",
    description: "Strawberry flavored frappe",
    price: "JD4.50"
  },
  {
    category: "Fruity Frappe",
    itemName: "Tangerine Frappe",
    description: "Tangerine flavored frappe",
    price: "JD4.50"
  },
  {
    category: "Fruity Frappe",
    itemName: "Mango Frappe",
    description: "Mango flavored frappe",
    price: "JD4.50"
  },
  {
    category: "Fruity Frappe",
    itemName: "Raspberry Frappe",
    description: "Raspberry flavored frappe",
    price: "JD4.50"
  },
  {
    category: "Fruity Frappe",
    itemName: "Blueberry Frappe",
    description: "Blueberry flavored frappe",
    price: "JD4.50"
  },
  {
    category: "Fruity Frappe",
    itemName: "Red Berries Frappe",
    description: "Red Berries flavored frappe",
    price: "JD4.50"
  }
];
export default fruityFrappeMenu;