import React from "react";
import { Link } from "react-router-dom";
import bg3 from "../../assets/images/background/bg-3.png";
import badge1 from "../../assets/images/resource/badge-1.png";
import img1 from "../../assets/images/resource/image-1.jpg";
import img2 from "../../assets/images/resource/image-2.jpg";

function Story() {
  return (
    <>
      <section className="story-section">
        <div className="left-bg">
          <img src={bg3} alt="" title="" />
        </div>

        <div className="auto-container">
          <div className="row clearfix">
            <div className="text-col col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <div
                className="inner wow fadeInLeft"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div className="title-box centered">
                  <div className="subtitle">
                    <span> Our story </span>
                  </div>
                  <div className="pattern-image">
                    <img
                      src={
                        require("../../assets/images/icons/separator.svg")
                          .default
                      }
                      alt="mySvgImage"
                    />
                  </div>
                  <h2>Every Flavor Tells a Story</h2>
                  <div className="text">
                    {/* In 2008, in the heart of Amman “Cioconat”, a charming
                    Mediterranean cuisine café and restaurant was born. The
                    genesis of this lovely place is traced to an unforgettable
                    trip to beautiful Italy, where flavors danced and aromas
                    were lovingly adorned. A man whose heart was entwined, with
                    a passion for food tasting, experienced devouring one of the
                    most unforgettable hot chocolate/hazelnut drinks, this was
                    when the name “Cioconat” came to life, and he decided on
                    bringing the distinctive flavors to Jordan. */}
                    It all started with a man's passion for food and an
                    unforgettable hot chocolate/hazelnut drink in Italy.
                    Cioconat, a charming Mediterranean cuisine café and
                    restaurant in Amman, was born.
                    <br/>
                    <br/>
                  </div>
                </div>
                <div className="booking-info">
                  <div className="bk-title">Book Through Call</div>
                  <div className="bk-no">
                    <Link href="tel:+962-(6)-541-2484">+962-(6)-541-2484</Link>
                  </div>

                  <div className="link-box">
                    <Link
                      href="/about"
                      className="theme-btn btn-style-two clearfix"
                    >
                      <span className="btn-wrap">
                        <span className="text-one">Learn More</span>
                        <span className="text-two">Learn More</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="image-col col-xl-7 col-lg-7 col-md-12 col-sm-12">
              <div
                className="inner wow fadeInRight"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div className="round-stamp">
                  <img src={badge1} alt="" />
                </div>
                <div className="images parallax-scene-1">
                  <div className="image" data-depth="0.15">
                    <img src={img1} alt="" style={{height:"400px"}} />
                  </div>
                  <div className="image" data-depth="0.30">
                    <img src={img2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Story;
