import React from "react";
import Startermenu from "../Allmenus/FirstMenu/Startermenu";
import Bredcom from "../Bredcrumb/Main";
import Specialmenu from "../Allmenus/Menmenu/Specialmenu";
import Listmenu from "../Allmenus/Menmenu/Listmenu";
import Secreservation from "../Allmenus/Menmenu/Secreservation";
import Reservation from "../Allmenus/FirstMenu/Reservation";
import Img from "../../assets/images/background/banner-image-7.png";
import food from "../../assets/images/resource/food.png";
import drinks from "../../assets/images/resource/drinks.png";
import shisha from "../../assets/images/resource/shisha.png";
import Smenu from "../UI/Accordion";
import Cat from "../UI/Cat";
import Smenu2 from "../UI/Smenu2";
import Smenu3 from "../UI/Smenu3";

function Main() {
  return (
    <>
      <Bredcom subtitle="Cioconat Menu" title="More Than 50 dishes made fresh every day" Img={Img} />
       
       <Cat />
       <div id="foodSection">

      <Smenu />
      </div>
      <div id="drinksSection">
      <Smenu2/>
      </div>
      <div id="shishaSection">
      
      <Smenu3/>
      </div>

      {/*<Startermenu />*/}
      {/*<Specialmenu />*/}
      {/*<Listmenu />*/}
      {/* <Secreservation /> */}
      {/* <Reservation /> */}
    </>
  );
}

export default Main;
