import React from "react";
import { Link } from "react-router-dom";
import bgone from "../../../assets/images/background/bg-16.png";
import bgtwo from "../../../assets/images/background/bg-17.png";
import bgthree from "../../../assets/images/background/bg-18.png";
import menuone from "../../../assets/images/resource/menus-1.jpg";
import menutwo from "../../../assets/images/resource/menus-2.jpg";

function Startermenu() {
  return (
    <>
      <section className="menu-one">
        <div className="right-bg">
          <img src={bgone} alt="" title="" />
        </div>
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>STARTER MENU</span>
            </div>

            <div className="pattern-image">
              <img
                src={
                  require("../../../assets/images/icons/separator.svg").default
                }
                alt="mySvgImage"
              />
            </div>

            <h2>Appetizers</h2>
          </div>

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menuone} alt="" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">
                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>
                          <Link to="#">L’exotique Quinoa Salad</Link>
                        </h5>
                      </div>
                      <div className="price">
                        <span>JD7.50</span>
                      </div>
                    </div>
                    <div className="text desc">
                      <Link to="#">
                        Quinoa, shrimp, avocado, cucumber, spring onion, parsley
                        topped with beetroot cherry
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>
                          <Link to="#">Greek Salad</Link>
                        </h5>
                      </div>
                      <div className="price">
                        <span>JD5.50</span>
                      </div>
                    </div>
                    <div className="text desc">
                      <Link to="#">
                        Crispy lettuce, with fresh herbs, cherry tomato, bell
                        pepper, oregano, cheese, olive
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>
                          <Link to="#">Pomelo Salad</Link>
                        </h5>
                      </div>
                      <div className="price">
                        <span>JD6.00</span>
                      </div>
                    </div>
                    <div className="text desc">
                      <Link to="#">
                        Baby rocket, lettuce, pomelo, avocado, cucumber, cherry
                        tomato, red cabbage, nuts
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>
                          <Link to="#">Kani Salad</Link>
                        </h5>
                      </div>
                      <div className="price">
                        <span>JD8.50</span>
                      </div>
                    </div>
                    <div className="text desc">
                      <Link to="#">
                        Japanese crab with cucumber, carrot in Thai dressing
                        topped with crispy panko
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="menu-one alternate">
        <div className="left-bg">
          <img src={bgtwo} alt="" title="" />
        </div>
        <div className="right-bg-2">
          <img src={bgthree} alt="" title="" />
        </div>
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Delicious</span>
            </div>

            <div className="pattern-image">
              <img
                src={
                  require("../../../assets/images/icons/separator.svg").default
                }
                alt="mySvgImage"
              />
            </div>

            <h2>Main Dishes</h2>
          </div>

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menutwo} alt="" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">
                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>
                          <Link to="#">Prime Beef Ribeye Silver</Link>
                        </h5>
                      </div>
                      <div className="price">
                        <span>JD27.00</span>
                      </div>
                    </div>
                    <div className="text desc">
                      <Link to="#">
                        Topped with mashed potato, vegetable sticks, fresh herbs
                        sauce
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>
                          <Link to="#">Prime Beef Tenderloin</Link>
                        </h5>
                      </div>
                      <div className="price">
                        <span>JD16.00</span>
                      </div>
                    </div>
                    <div className="text desc">
                      <Link to="#">
                        Topped with mashed potato, vegetable puree’, mushroom
                        sauce
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>
                          <Link to="#">Grilled Salmon</Link>
                        </h5>
                      </div>
                      <div className="price">
                        <span>JD13.00</span>
                      </div>
                    </div>
                    <div className="text desc">
                      <Link to="#">
                        Marinated with crispy garlic pistachio topped with
                        quinoa, veggie mousse
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>
                          <Link to="#">Creamy Garlic Chicken</Link>
                        </h5>
                      </div>
                      <div className="price">
                        <span>JD10.00</span>
                      </div>
                    </div>
                    <div className="text desc">
                      <Link to="#">
                        Seasoning chicken breast dredged in flour, parmesan
                        cheese
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Startermenu;
