const mocktailsMenu = [
  {
    category: "Mocktails",
    itemName: "Cioconat Special Mocktail",
    description: "Orange juice, cinnamon, strawberry, honey, soda",
    price: "JD3.40"
  },
  {
    category: "Mocktails",
    itemName: "Blackberry Green Apple",
    description: "Lemon rancho, blackberry, green apple, soda",
    price: "JD3.50"
  },
  {
    category: "Mocktails",
    itemName: "Basil Cherry Tomato",
    description: "Basil, cherry tomato, lemon juice, soda",
    price: "JD3.40"
  },
  {
    category: "Mocktails",
    itemName: "Lavender Yuzu Pure",
    description: "Yuzu pure, lavender, lemon rantcho, soda",
    price: "JD3.40"
  },
  {
    category: "Mocktails",
    itemName: "Blue Orange",
    description: "Blue caracao, orange, soda",
    price: "JD3.50"
  },
  {
    category: "Mocktails",
    itemName: "Passion Granate",
    description: "Pomegranate, passion fruit, soda",
    price: "JD3.50"
  },
  {
    category: "Mocktails",
    itemName: "Red Power",
    description: "Lemon juice, grenadine, red bull",
    price: "JD4.50"
  },
  {
    category: "Mocktails",
    itemName: "Rose Lemonade",
    description: "Rose, lemon juice, soda",
    price: "JD3.40"
  },
  {
    category: "Mocktails",
    itemName: "Blue Curacao",
    description: "Lemon, sugar syrup, soda",
    price: "JD3.50"
  }
];

export default mocktailsMenu;