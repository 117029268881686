import React from "react";
import food from "../../assets/images/resource/food.png";
import drinks from "../../assets/images/resource/drinks.png";
import shisha from "../../assets/images/resource/shisha.png";
import { Link } from "react-scroll";

function Cat() {
  return (
    <>
      
          
<div className="link-container">
        

            <Link to="foodSection" smooth={true} duration={500}>
              <div className="icon-container">
                <img src={food} alt="Food" />
                <span>Food</span>
              </div>
            </Link>

            <Link to="drinksSection" smooth={true} duration={500}>
              <div className="icon-container">
                <img src={drinks} alt="Drinks" />
                <span>Drinks</span>
              </div>
            </Link>

            <Link to="shishaSection" smooth={true} duration={500}>
              <div className="icon-container">
                <img src={shisha} alt="Shisha" />
                <span>Shisha</span>
              </div>
            </Link>
          </div>
    

    </>
  );
}

export default Cat;
