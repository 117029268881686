const saladAppetizersMenu = [
  {
    category: "Salad & Appetizers",
    itemName: "L’exotique Quinoa Salad",
    description: "Quinoa, shrimp, avocado, cucumber, spring onion, parsley topped with beetroot cherry, vinaigrette green oil",
    price: "JD7.50"
  },
  {
    category: "Salad & Appetizers",
    itemName: "Greek Salad",
    description: "Crispy lettuce, with fresh herbs, cherry tomato, bell pepper, oregano, cheese, olive, sliced cucumber with french dressing",
    price: "JD5.50"
  },
  {
    category: "Salad & Appetizers",
    itemName: "Pomelo Salad",
    description: "Baby rocket, lettuce, pomelo, avocado, cucumber, cherry tomato, red cabbage, nuts, sesame seed, aioli citrus dressing",
    price: "JD6.00"
  },
  {
    category: "Salad & Appetizers",
    itemName: "Kani Salad",
    description: "Japanese crab with cucumber, carrot in Thai dressing topped with crispy panko",
    price: "JD8.50"
  },
  {
    category: "Salad & Appetizers",
    itemName: "Famous Salad",
    description: "Baby arugula leaves, quinoa, pomegranate, bell pepper gazpacho, bread bruschetta toto fruity balsamic dressing",
    price: "JD5.50"
  },
  {
    category: "Salad & Appetizers",
    itemName: "Traditional Chicken Caesar Salad",
    description: "A light refreshing mixture of crispy romaine lettuce, seasoning chicken, croutons, parmesan cheese, home-made caesar dressing",
    price: "JD6.50"
  },
  {
    category: "Salad & Appetizers",
    itemName: "Crunchy Halloumi Panzanella",
    description: "Crispy mixed leaves topped with cherry tomato confit, halloumi cheese, green olive, strawberry, cucumber, caramelized walnut, pesto citrus dressing",
    price: "JD6.00"
  }
];

export default saladAppetizersMenu;