import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Bgtwo from "../../assets/images/background/image-2.jpg";
import OwlCarousel from "react-owl-carousel";
import author1 from "../../assets/images/resource/author-thumb-1.jpg";
import author2 from "../../assets/images/resource/author-thumb-2.jpg";
import author3 from "../../assets/images/resource/author-thumb-3.jpg";

function Contact() {
  const [flag, setflag] = useState(false);
  const slider1 = useRef();
  const slider2 = useRef();
  const duration = 500;
  const syncPosition = (e) => {
    if (!flag) {
      setflag(false);
      if (slider2.current) {
        slider2.current.to(e.item.index, duration);
      }
      setflag(false);
    }
  };

  return (
    <>
      <div className="testimonials-section">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(${Bgtwo})` }}
        ></div>
        <div className="auto-container">
          <div className="carousel-box owl-theme">
            <OwlCarousel
              className="testi-top owl-theme owl-carousel"
              ref={slider1}
              items={1}
              loop
              margin={10}
              nav
              autoplay
              onChange={(e) => syncPosition(e)}
            >
              <div className="slide-item item">
                <div className="slide-content">
                  <div className="quotes">”</div>
                  <div className="text quote-text">
                    I wanted to thank you for inviting me down for that amazing
                    dinner the other night. The food was extraordinary.
                  </div>
                </div>
              </div>
              <div className="slide-item item">
                <div className="slide-content">
                  <div className="quotes">”</div>
                  <div className="text quote-text">
                    I wanted to thank you for inviting me down for that amazing
                    dinner the other night. The food was extraordinary.
                  </div>
                </div>
              </div>
              <div className="slide-item item">
                <div className="slide-content">
                  <div className="quotes">”</div>
                  <div className="text quote-text">
                    I wanted to thank you for inviting me down for that amazing
                    dinner the other night. The food was extraordinary.
                  </div>
                </div>
              </div>
              <div className="slide-item item">
                <div className="slide-content">
                  <div className="quotes">”</div>
                  <div className="text quote-text">
                    I wanted to thank you for inviting me down for that amazing
                    dinner the other night. The food was extraordinary.
                  </div>
                </div>
              </div>
              <div className="slide-item item">
                <div className="slide-content">
                  <div className="quotes">”</div>
                  <div className="text quote-text">
                    I wanted to thank you for inviting me down for that amazing
                    dinner the other night. The food was extraordinary.
                  </div>
                </div>
              </div>
              <div className="slide-item item">
                <div className="slide-content">
                  <div className="quotes">”</div>
                  <div className="text quote-text">
                    I wanted to thank you for inviting me down for that amazing
                    dinner the other night. The food was extraordinary.
                  </div>
                </div>
              </div>
            </OwlCarousel>
            <div className="separator">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="thumbs-carousel-box">
              <OwlCarousel
                className="testi-thumbs owl-theme owl-carousel"
                ref={slider2}
                items={1}
                loop
                margin={10}
                nav
                autoplay
              >
                <div className="slide-item item">
                  <div className="image">
                    <img src={author1} alt="" />
                  </div>

                  <div className="auth-title">Sam Jhonson</div>
                </div>
                <div className="slide-item item">
                  <div className="image">
                    <img src={author2} alt="" />
                  </div>

                  <div className="auth-title">Ian Botham</div>
                </div>
                <div className="slide-item item">
                  <div className="image">
                    <img src={author3} alt="" />
                  </div>
                  <div className="auth-title">Dan Bitson</div>
                </div>
                <div className="slide-item item">
                  <div className="image">
                    <img src={author1} alt="" />
                  </div>
                  <div className="auth-title">Sam Jhonson</div>
                </div>
                <div className="slide-item item">
                  <div className="image">
                    <img src={author2} alt="" />
                  </div>
                  <div className="auth-title">Ian Botham</div>
                </div>
                <div className="slide-item item">
                  <div className="image">
                    <img src={author3} alt="" />
                  </div>
                  <div className="auth-title">Dan Bitson</div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>

      <section className="reserve-section">
        <div className="auto-container">
          <div className="outer-box">
            <div className="row clearfix">
              <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="title">
                    <h2>Online Reservation</h2>
                    <div className="request-info">
                      Booking request <Link to="#">+962-(6)-541-2484</Link> or
                      fill out the order form
                    </div>
                  </div>
                  <div className="default-form reservation-form">
                    <form method="post" action="index.html">
                      <div className="row clearfix">
                        <div className="form-group col-lg-6 col-md-6 col-sm-12">
                          <div className="field-inner">
                            <input
                              type="text"
                              name="fieldname"
                              placeholder="Your Name"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12">
                          <div className="field-inner">
                            <input
                              type="text"
                              name="fieldname"
                              placeholder="Phone Number"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <div className="field-inner">
                            <span className="alt-icon far fa-user"></span>
                            <select className="l-icon">
                              <option>1 Person</option>
                              <option>2 Person</option>
                              <option>3 Person</option>
                              <option>4 Person</option>
                              <option>5 Person</option>
                              <option>6 Person</option>
                              <option>7 Person</option>
                            </select>
                            <span className="arrow-icon far fa-angle-down"></span>
                          </div>
                        </div>
                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <div className="field-inner">
                            <span className="alt-icon far fa-calendar"></span>
                            <input
                              className="l-icon datepicker"
                              type="text"
                              name="fieldname"
                              placeholder="DD-MM-YYYY"
                              required
                              readOnly
                            />
                            <span className="arrow-icon far fa-angle-down"></span>
                          </div>
                        </div>
                        <div className="form-group col-lg-4 col-md-12 col-sm-12">
                          <div className="field-inner">
                            <span className="alt-icon far fa-clock"></span>
                            <select className="l-icon">
                              <option>08 : 00 am</option>
                              <option>09 : 00 am</option>
                              <option>10 : 00 am</option>
                              <option>11 : 00 am</option>
                              <option>12 : 00 pm</option>
                              <option>01 : 00 pm</option>
                              <option>02 : 00 pm</option>
                              <option>03 : 00 pm</option>
                              <option>04 : 00 pm</option>
                              <option>05 : 00 pm</option>
                              <option>06 : 00 pm</option>
                              <option>07 : 00 pm</option>
                              <option>08 : 00 pm</option>
                              <option>09 : 00 pm</option>
                              <option>10 : 00 pm</option>
                            </select>
                            <span className="arrow-icon far fa-angle-down"></span>
                          </div>
                        </div>
                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                          <div className="field-inner">
                            <textarea
                              name="fieldname"
                              placeholder="Message"
                              required
                            ></textarea>
                          </div>
                        </div>
                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                          <div className="field-inner">
                            <button
                              type="submit"
                              className="theme-btn btn-style-one clearfix"
                            >
                              <span className="btn-wrap">
                                <span className="text-one">book a table</span>
                                <span className="text-two">book a table</span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="info-col col-lg-4 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="title">
                    <h2>Contact Us</h2>
                  </div>
                  <div className="data">
                    <div className="booking-info">
                      <div className="bk-title">Booking request</div>
                      <div className="bk-no">
                        <Link to="tel:+962 (6) 541 2484">
                          +962 (6) 541 2484
                        </Link>
                      </div>
                    </div>
                    <div className="separator">
                      <span></span>
                    </div>
                    <ul className="info">
                      <li>
                        <strong>Location</strong>
                        <br />
                        Al-Sha'b St, Dabouq, Amman 11822, JO
                      </li>
                      <li>
                        <strong>Daily</strong>
                        <br />
                        9.00 am to 1.00 am
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
