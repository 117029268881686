const coldCoffeeMenu = [
  {
    category: "Cold Coffee",
    itemName: "Iced Americano",
    description: "Iced Americano",
    price: "JD4.00"
  },
  {
    category: "Cold Coffee",
    itemName: "Iced Latté",
    description: "Iced Latté",
    price: "JD4.25"
  },
  {
    category: "Cold Coffee",
    itemName: "Iced Mocha",
    description: "Iced Mocha",
    price: "JD4.50"
  },
  {
    category: "Cold Coffee",
    itemName: "Iced Spanish Latté",
    description: "Iced Spanish Latté",
    price: "JD4.50"
  },
  {
    category: "Cold Coffee",
    itemName: "Praline Ice Latté",
    description: "Praline Ice Latté",
    price: "JD4.50"
  },
  {
    category: "Cold Coffee",
    itemName: "Frappuccino",
    description: "Frappuccino",
    price: "JD4.75"
  }
];

export default coldCoffeeMenu;