const bakedOvenMenu = [
  {
    category: "Baked Oven",
    itemName: "Manakish zaatar",
    description: "Manakish zaatar",
    price: "JD3.00"
  },
  {
    category: "Baked Oven",
    itemName: "Manakish cheese",
    description: "Manakish cheese",
    price: "JD4.00"
  },
  {
    category: "Baked Oven",
    itemName: "Manakish cheese & zaatar",
    description: "Manakish cheese & zaatar",
    price: "JD4.00"
  },
  {
    category: "Baked Oven",
    itemName: "Manakish labneh with baby rocket",
    description: "Manakish labneh with baby rocket",
    price: "JD4.00"
  },
  {
    category: "Baked Oven",
    itemName: "Manakish turkey & cheese",
    description: "Manakish turkey & cheese",
    price: "JD4.25"
  }
];

export default bakedOvenMenu;