const casseroleMenu = [
  {
    category: "Casserole",
    itemName: "Chicken Tawook Provencal",
    description: "Grilled chicken cubes with butter garlic sauce mushroom, emmental cheese baked in oven",
    price: "JD8.00"
  },
  {
    category: "Casserole",
    itemName: "Seafood Termidore",
    description: "Sauteed seafood with bell pepper, garlic, onion, mushroom, creamy saffron sauce, melted cheese",
    price: "JD10.50"
  },
  {
    category: "Casserole",
    itemName: "Authentic Meatballs",
    description: "Stuffed meatball with cheese cooked with tomato concasse, croquette potato",
    price: "JD9.50"
  }
];

export default casseroleMenu;