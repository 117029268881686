// Importing arrays from each file in the same 'data' folder
import breakfastMenu from './breakfastMenu';
import bakedOvenMenu from './bakedOvenMenu';
import casseroleMenu from './casseroleMenu';
import cocktailsMenu from './cocktailsMenu';
import coffeeTeaMenu from './coffeeTeaMenu';
import coldCoffeeMenu from './coldCoffeeMenu';
import fruityFrappeMenu from './fruityFrappeMenu';
import hotAppetizersMenu from './hotAppetizersMenu';
import juicesSmoothiesMenu from './juicesSmoothiesMenu';
import mainCourseMenu from './mainCourseMenu';
import milkshakeMenu from './milkshakeMenu';
import mocktailsMenu from './mocktailsMenu';
import pastaMenu from './pastaMenu';
import pizzaMenu from './pizzaMenu';
import saladAppetizersMenu from './saladAppetizersMenu';
import sandwichesMenu from './sandwichesMenu';
import shishaMenu from './shishaMenu';
import softDrinkMenu from './softDrinkMenu';
import soupMenu from './soupMenu';
import yoghurtMenu from './yoghurtMenu';

// Grouping arrays
const breakfastMenus = [breakfastMenu, bakedOvenMenu];
const saladA = [saladAppetizersMenu, hotAppetizersMenu, soupMenu];
const pizzaPasta = [pizzaMenu, pastaMenu];
const sandMainCas = [sandwichesMenu, casseroleMenu, mainCourseMenu];
const drinksMenu = [coffeeTeaMenu, coldCoffeeMenu, cocktailsMenu, fruityFrappeMenu, mocktailsMenu, milkshakeMenu, yoghurtMenu, softDrinkMenu, juicesSmoothiesMenu];
const food = [breakfastMenu, bakedOvenMenu, saladAppetizersMenu, hotAppetizersMenu, soupMenu, pizzaMenu, pastaMenu, sandwichesMenu, casseroleMenu, mainCourseMenu];

// Function to group items by category
const groupByCategory = (menuItems) => {
  return menuItems.reduce((acc, item) => {
    acc[item.category] = acc[item.category] || [];
    acc[item.category].push(item);
    return acc;
  }, {});
};

// Applying the function to each menu group
const menuByCategory = groupByCategory(food.flat());
const saladMenuByCategory = groupByCategory(saladA.flat());
const pizzaPastaByCategory = groupByCategory(pizzaPasta.flat());
const sandMainCasByCategory = groupByCategory(sandMainCas.flat());
const drinksMenuByCategory = groupByCategory(drinksMenu.flat());
const shishaGroup = groupByCategory(shishaMenu);


// Exporting the grouped menus
export { 
  menuByCategory, 
  saladMenuByCategory, 
  pizzaPastaByCategory, 
  sandMainCasByCategory, 
  drinksMenuByCategory,
  shishaGroup
};
