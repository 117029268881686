const pizzaMenu = [
  {
    category: "Pizza",
    itemName: "Cioconat Pizza",
    description: "Tomato sauce, marinated chicken, pineapple, turkey, dry tomato, baby spinach, mozzarella cheese",
    price: "JD8.00"
  },
  {
    category: "Pizza",
    itemName: "Chicken Alfredo Pizza",
    description: "Creamy chicken Alfredo, mushrooms cherry tomato, mozzarella cheese",
    price: "JD7.00"
  },
  {
    category: "Pizza",
    itemName: "Pizza Margarita",
    description: "Tomato sauce with mozzarella cheese",
    price: "JD5.00"
  },
  {
    category: "Pizza",
    itemName: "Pizza Siciliana",
    description: "Tomato sauce, mozzarella cheese, marinated grilled bell pepper sliced fresh mushroom, Italian salami & fresh sliced tomato",
    price: "JD6.50"
  },
  {
    category: "Pizza",
    itemName: "Pizza Vegetarian",
    description: "Tomato sauce, mozzarella cheese, bell pepper, mushroom, cherry tomato",
    price: "JD6.00"
  }
];
export default pizzaMenu;