const coffeeTeaMenu = [
  {
    category: "Coffee & Tea",
    itemName: "Espresso",
    description: "Ristretto or Longo",
    price: "JD3.00"
  },
  {
    category: "Coffee & Tea",
    itemName: "Doppio",
    description: "Doppio",
    price: "JD4.00"
  },
  {
    category: "Coffee & Tea",
    itemName: "Espresso Macchiato",
    description: "Espresso Macchiato",
    price: "JD3.50"
  },
  {
    category: "Coffee & Tea",
    itemName: "Espresso Cortado",
    description: "Espresso shot with steamed milk shot",
    price: "JD3.50"
  },
  {
    category: "Coffee & Tea",
    itemName: "Espresso Affogato",
    description: "Espresso shot with vanilla ice cream",
    price: "JD4.00"
  },
  {
    category: "Coffee & Tea",
    itemName: "Cappuccino",
    description: "Cappuccino",
    price: "JD4.10"
  },
  {
    category: "Coffee & Tea",
    itemName: "Café Latte",
    description: "Café Latte",
    price: "JD4.10"
  },
  {
    category: "Coffee & Tea",
    itemName: "Salted Caramel Latte",
    description: "Salted Caramel Latte",
    price: "JD4.50"
  },
  {
    category: "Coffee & Tea",
    itemName: "Spanish Latte",
    description: "Espresso, condensed milk, steamed milk",
    price: "JD4.50"
  },
  {
    category: "Coffee & Tea",
    itemName: "Dark Mocha",
    description: "Dark Mocha",
    price: "JD4.10"
  },
  {
    category: "Coffee & Tea",
    itemName: "White Mocha",
    description: "White Mocha",
    price: "JD4.10"
  },
  {
    category: "Coffee & Tea",
    itemName: "Cinnamon Dolce Latte",
    description: "Espresso, cinnamon, caramel, vanilla, steamed milk whipped cream",
    price: "JD4.50"
  },
  {
    category: "Coffee & Tea",
    itemName: "Coco Mocha",
    description: "Dark chocolate sauce, coconut, espresso, steamed milk",
    price: "JD4.50"
  },
  {
    category: "Coffee & Tea",
    itemName: "Lavender Coffee",
    description: "Espresso, steamed milk, lavender",
    price: "JD4.50"
  },
  {
    category: "Coffee & Tea",
    itemName: "Caramel Macchiato",
    description: "Caramel Macchiato",
    price: "JD4.50"
  },
  {
    category: "Coffee & Tea",
    itemName: "Double Cream Brulee",
    description: "Espresso, steamed milk, cream brulee",
    price: "JD4.25"
  },
  {
    category: "Coffee & Tea",
    itemName: "American Coffee",
    description: "American Coffee",
    price: "JD4.00"
  },
  {
    category: "Coffee & Tea",
    itemName: "Americano",
    description: "Americano",
    price: "JD4.00"
  },
  {
    category: "Coffee & Tea",
    itemName: "Turkish Coffee",
    description: "Turkish Coffee",
    price: "JD4.00"
  },
  {
    category: "Coffee & Tea",
    itemName: "Nescafe",
    description: "Classic, gold, decaf",
    price: "JD4.00"
  },
  {
    category: "Coffee & Tea",
    itemName: "Hot Chocolate",
    description: "Hot Chocolate",
    price: "JD4.00"
  },
  {
    category: "Coffee & Tea",
    itemName: "Flavored Hot Chocolate",
    description: "Flavored Hot Chocolate",
    price: "JD4.25"
  },
  {
    category: "Coffee & Tea",
    itemName: "White Chocolate",
    description: "White Chocolate",
    price: "JD4.00"
  },
  {
    category: "Coffee & Tea",
    itemName: "Regular Tea",
    description: "Regular Tea",
    price: "JD3.25"
  },
  {
    category: "Coffee & Tea",
    itemName: "Flavored Tea",
    description: "Flavored Tea",
    price: "JD3.50"
  },
  {
    category: "Coffee & Tea",
    itemName: "Karak Tea",
    description: "Karak Tea",
    price: "JD3.75"
  }
];

export default coffeeTeaMenu;